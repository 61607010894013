import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as en from './i18n/en.json'
import * as fi from './i18n/fi.json'
import LanguageDetector from 'i18next-browser-languagedetector';


i18n.on('languageChanged', function (lng) {
  // if the language we switched to is the default language we need to remove the /fi from URL
  if (lng === 'fi') {
    if (window.location.pathname.includes('/' + 'fi')) {
      const newUrl = window.location.href.replace('/fi', '')
      window.location.replace(newUrl)
    }
  }
})

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: en
  },
  fi: {
    translation: fi
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'fi',
    supportedLngs: ['fi','en'],
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;