import React from 'react';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import {ProgressBar} from '../components/ProgressBar';
import FormProvider from '../context/formContext';
import OfferRequestProvider from '../context/offerRequestContext';
import {OfferRequest} from '../containers/offerRequest';
import {Container} from '../components/Containter';

export const OfferRequestPage = () => {
  return (
    <div>
      <Header />
      <OfferRequestProvider>
        <FormProvider>
          <ProgressBar />
          <div className="py-6">
            <Container>
              <OfferRequest />
            </Container>
          </div>

        </FormProvider>
      </OfferRequestProvider>
      <Footer />
    </div>
  )
}