import React from 'react'
import {CheckIcon} from '@heroicons/react/solid';
import {FormContext, FormContextType} from '../../context/formContext';
import {useTranslation} from 'react-i18next';

export const Success = () => {
  const {setCurrentStep} = React.useContext(FormContext) as FormContextType;
  const { t } = useTranslation();

  return (
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative overflow-hidden px-4 pt-5 pb-4 text-left sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t( 'Offer request has been sent successfully')}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {t('You will receive offer to your email in a short moment!')}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-brand focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2 sm:text-sm"
                onClick={() => setCurrentStep(1)}
              >
                {t('Calculate again')}
              </button>
            </div>
          </div>
      </div>
  )
}