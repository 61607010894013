import React from 'react'
import {FormContext, FormContextType} from '../context/formContext';
import {BasicInfo} from '../components/forms/BasicInfo';
import {From} from '../components/forms/From';
import {To} from '../components/forms/To';
import {ExtraInfo} from '../components/forms/ExtraInfo';
import {Success} from '../components/forms/Success';



export const OfferRequest = () => {
  const { step } = React.useContext(FormContext) as FormContextType;

  switch (step) {
    case 1:
      return <BasicInfo/>
    case 2:
      return <From />
    case 3:
      return <To />
    case 4:
      return <ExtraInfo />
    case 5:
      return <Success />
    default:
      return <div>No step found</div>
  }
}