import React from 'react';
import {Header} from '../components/Header';
import {Footer} from '../components/Footer';
import {CheckIcon} from '@heroicons/react/solid';
import {useTranslation} from 'react-i18next';

export const SuccessPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div className="relative overflow-hidden px-4 pt-5 pb-4 text-left sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t('Thank you for selecting Mainio Muuttopalvelu')}.
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {t('We will contact you on next business day')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}