import React from 'react'
import {Button} from '../Button';
import {FormContext, FormContextType} from '../../context/formContext';
import {number, object, string} from 'yup';
import {Field, Form, Formik} from 'formik';
import {ExclamationCircleIcon} from '@heroicons/react/outline';
import {OfferRequestContext, OfferRequestContextType} from '../../context/offerRequestContext';
import {DatePicker} from '../DatePicker'
import {useTranslation} from 'react-i18next';

const BasicInfoSchema = object().shape({
  firstName: string().min(2).max(50).required(),
  lastName: string().min(2).max(50).required(),
  telephone: string().min(2).max(15).required(),
  persons: number().required(),
  deliveryDate: string().required(),
  email: string().email().required(),
});

export const BasicInfo = () => {
  const {nextStep} = React.useContext(FormContext) as FormContextType;
  const {upsertOfferRequest, offerRequest} = React.useContext(OfferRequestContext) as OfferRequestContextType;
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        firstName: offerRequest?.customer.firstName || '',
        lastName: offerRequest?.customer.lastName || '',
        email: offerRequest?.customer.email || '',
        telephone: offerRequest?.customer.telephone || '',
        persons: offerRequest?.customer.persons || '1',
        deliveryDate: offerRequest?.deliveryDate ||  '',
        wholeLocation: offerRequest?.wholeLocation,
        timeInCurrentLocation: offerRequest?.timeInCurrentLocation || ''
      }}
      validationSchema={BasicInfoSchema}
      onSubmit={values => {
        // same shape as initial values
        console.log(values);
        const {firstName, lastName, email, telephone, persons, deliveryDate, timeInCurrentLocation, wholeLocation} = values
        upsertOfferRequest({customer: {firstName, lastName, email, telephone, persons}, deliveryDate, timeInCurrentLocation, wholeLocation})
        nextStep()
      }}
    >
      {({errors, touched, handleChange, setFieldValue}) => (
        <Form>
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Basic info')}</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('Fill basic information about the move')}</p>
                </div>
                <div className="space-y-6 sm:space-y-5">
                  <div
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('Firstname')}
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Field
                        type="text"
                        name="firstName"
                        id="firstName"
                        autoComplete="given-name"
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:max-w-xs sm:text-sm"
                      />
                      {errors.firstName && touched.firstName &&
                        <>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                          </div>
                          <p className="mt-2 text-sm text-red-600" id="email-error">
                            {t('Firstname has to be between 2 and 50 characters long')}
                          </p>
                        </>
                      }
                    </div>
                  </div>

                  <div
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('Lastname')}
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Field
                        type="text"
                        name="lastName"
                        id="lastName"
                        autoComplete="family-name"
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:max-w-xs sm:text-sm"
                      />
                      {errors.lastName && touched.lastName &&
                        <>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                          </div>
                          <p className="mt-2 text-sm text-red-600" id="email-error">
                            {t('Lastname has to be between 2 and 50 characters long')}
                          </p>
                        </>
                      }
                    </div>
                  </div>

                  <div
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Email
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                      />
                      {errors.email && touched.email &&
                        <>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                          </div>
                          <p className="mt-2 text-sm text-red-600" id="email-error">
                            {t('Email address needs to be in address@example.com format')}
                          </p>
                        </>
                      }
                    </div>
                  </div>

                  <div
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="telephone" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('Telephone')}
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Field
                        type="text"
                        name="telephone"
                        id="telephone"
                        autoComplete="tel-national"
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:max-w-xs sm:text-sm"
                      />
                      {errors.telephone && touched.telephone &&
                        <>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                          </div>
                          <p className="mt-2 text-sm text-red-600" id="email-error">
                            {t('Telephone needs to be between 2 and 15 numbers long')}
                          </p>
                        </>
                      }
                    </div>
                  </div>


                  <div
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="persons" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('Number of residents')}
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Field
                        type="text"
                        name="persons"
                        id="persons"
                        autoComplete="off"
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                      />
                      {errors.persons && touched.persons &&
                        <>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                          </div>
                          <p className="mt-2 text-sm text-red-600" id="email-error">
                            {t('Input number of residents')}
                          </p>
                        </>
                      }
                    </div>
                  </div>

                  <div
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="deliveryDate" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('Proposed moving day')}
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <DatePicker inputId="deliveryDate" handleChange={handleChange} setFieldValue={setFieldValue}/>

                      {errors.deliveryDate && touched.deliveryDate &&
                        <>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                          </div>
                          <p className="mt-2 text-sm text-red-600" id="email-error">
                            {t('Insert proposed moving day')}
                          </p>
                        </>
                      }
                    </div>
                  </div>

                  <div
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="wholeLocation" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('Is the whole apartment going to be moved?')}
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <fieldset className="mt-4">
                        <legend className="sr-only">{t('Is the whole apartment going to be moved?')}</legend>
                        <div className="space-y-4">
                          <div className="flex items-center">
                            <Field id="wholeLocation-true" name="wholeLocation" type="radio" checked
                                   className="h-4 w-4 border-gray-300 text-brand focus:ring-brand" value="true"/>
                            <label htmlFor="wholeLocation"
                                   className="ml-3 block text-sm font-medium text-gray-700">{t('Yes')}</label>
                          </div>

                          <div className="flex items-center">
                            <Field id="wholeLocation-false" name="wholeLocation" type="radio"
                                   className="h-4 w-4 border-gray-300 text-brand focus:ring-brand" value="false"/>
                            <label htmlFor="wholeLocation"
                                   className="ml-3 block text-sm font-medium text-gray-700">{t('No')}</label>
                          </div>

                        </div>
                      </fieldset>
                      <p className="mt-2 text-sm text-gray-500">
                        {t('Select Yes if most of the goods are going to be moved and No if clearly only some of the goods are going to be moved')}
                      </p>
                    </div>
                  </div>
                  <div
                    className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="timeInCurrentLocation"
                           className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('How long have you lived in the current apartment?')}
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Field
                        type="text"
                        name="timeInCurrentLocation"
                        id="timeInCurrentLocation"
                        autoComplete="off"
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:max-w-xs sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="pt-5 pb-12">
              <div className="flex justify-center">
                <Button type="submit">{t('Next')}</Button>
              </div>
            </div>
          </div>
        </Form>
      )}</Formik>
  )
}