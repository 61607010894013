import * as React from 'react';

export type FormContextType = {
  step: number;
  nextStep: () => void
  previousStep: () => void
  setCurrentStep: (step: number) => void
}

export const FormContext = React.createContext<FormContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const FormProvider: React.FC<Props> = ({children}) => {
  const [step, setStep] = React.useState<number>(1)
  const nextStep = () => {
    setStep(step + 1)
  }
  const previousStep = () => {
    setStep(step - 1)
  }
  const setCurrentStep = (step: number) => {
    setStep(step)
  }


  return (
    <FormContext.Provider value={{ step, nextStep, previousStep, setCurrentStep }}>
      {children}
    </FormContext.Provider>
  );
};

export default FormProvider