import * as React from 'react';
import {OfferRequest} from '@api/models/offerRequest';
import {createOfferRequest} from '../service/OfferService';
import {useTranslation} from 'react-i18next';

export type OfferRequestContextType = {
  offerRequest: OfferRequest|null;
  sendOfferRequest: (offer: OfferRequest) => Promise<OfferRequest|undefined>
  upsertOfferRequest: (offer: Partial<OfferRequest>) => OfferRequest
}

export const OfferRequestContext = React.createContext<OfferRequestContextType | null>(null);

interface Props {
  children: React.ReactNode;
}

const OfferRequestProvider: React.FC<Props> = ({children}) => {
  const {i18n} = useTranslation()
  const [offerRequest, setOfferRequest] = React.useState<OfferRequest>({
    customer: {email: '', firstName: '', lastName: '', persons: '', telephone: ''},
    deliveryDate: '',
    description: '',
    onlyDriver: false,
    pianoIncluded: false,
    moveFrom: {
      city: '',
      elevator: false,
      elevatorSize: '0',
      floor: '1',
      houseType: '',
      postalCode: '',
      size: '',
      street: ''
    },
    moveTo: {city: '', elevator: false, elevatorSize: '0', floor: '1', houseType: '', postalCode: '', size: '', street: ''},
    providerId: 'mainio-muuttopalvelu',
    timeInCurrentLocation: '',
    wholeLocation: true,
    createdAt: new Date(),
    language: i18n.language
  })
  const upsertOfferRequest = (data: Partial<OfferRequest>) => {
    const updated = {...offerRequest, language: i18n.language, ...data}
    setOfferRequest(updated)
    return updated
  }
  const sendOfferRequest = async (data: OfferRequest) => {
    return await createOfferRequest(data)
  }

  return (
    <OfferRequestContext.Provider value={{ offerRequest, upsertOfferRequest, sendOfferRequest }}>
      {children}
    </OfferRequestContext.Provider>
  );
};

export default OfferRequestProvider