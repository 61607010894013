import React from 'react'


type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
};

export const Button: React.FC<ButtonProps> = ({children, ...props}) => (
  <button
    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-brand py-4 px-8 text-md font-medium text-white shadow-sm border-2 hover:bg-white hover:text-brand hover:border-brand focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
    {...props}
  >
    {children}
  </button>
)