import { useRouteError } from 'react-router-dom';
import {Header} from '../components/Header';
import React from 'react';
import {Footer} from '../components/Footer';

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <Header />
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <Footer />
    </div>
  );
}