import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { Helmet } from 'react-helmet'
import {OfferRequestPage} from './pages/OfferRequestPage';
import {SuccessPage} from './pages/SuccessPage';
import {ErrorPage} from './pages/ErrorPage';
import {useTranslation} from 'react-i18next';

const router = createBrowserRouter([
  {
    path: '/',
    element: <OfferRequestPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/:locale',
    element: <OfferRequestPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/success',
    element: <SuccessPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/:locale/success',
    element: <SuccessPage />,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  const { t } = useTranslation();

  return (
    <div className="App">
      <Helmet>
        <title>{t('meta.title')}</title>
        <meta name="description" content={t('meta.description') || ''} />
        <meta name="theme-color" content="#3ebb9c" />
      </Helmet>
      <RouterProvider router={router} />

    </div>
  );
}

export default App;
