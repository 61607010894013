import React from 'react'
import { Link } from 'react-router-dom';

export const Header = () => {
  return (
    <header>
      <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="flex flex-wrap w-full items-center justify-between border-b border-gray-200 lg:border-none">
          <div className="order-2 md:order-1">
            <img className="h-12 md:h-24 w-auto" src="https://cdn.mainiomuuttopalvelu.fi/images/AAA-logo-2022-FI-1920w.png" alt=""/>
          </div>
          <div className="flex items-center justify-center w-full md:w-auto order-1 md:order-2">
            <Link to={'/'}>
              <span className="sr-only">Mainio Muuttopalvelu</span>
              <img className="h-36 w-auto" src="https://cdn.mainiomuuttopalvelu.fi/images/Mainio_logo-1920w.png" alt=""/>
            </Link>
          </div>
          <div className="order-3 grow md:w-auto md:grow-0">
            <a href="https://mainiomuuttopalvelu.fi"
               className="inline-block py-2 px-4 text-base font-medium text-brand flex justify-end">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                   stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round"
                      d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
              </svg>

              <span className="ml-1">mainiomuuttopalvelu.fi</span>
            </a>
          </div>
        </div>
      </nav>
    </header>
  )
}