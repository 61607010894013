import * as React from 'react';

import {useState, useEffect, FC} from 'react';
import {
  format,
  subMonths,
  addMonths,
  subYears,
  addYears,
  isEqual,
  getDaysInMonth,
  getISODay, isBefore
} from 'date-fns';
import {fi} from 'date-fns/locale'

import {Field} from 'formik';

type DatepickerType = 'date' | 'month' | 'year';

type Props = {
  inputId: string;
  handleChange: {
    /** Classic React change handler, keyed by input name */
    (e: React.ChangeEvent<HTMLInputElement>): void;
    /** Preact-like linkState. Will return a handleChange function.  */<T = string | React.ChangeEvent<HTMLInputElement>>(field: T): T extends React.ChangeEvent<HTMLInputElement> ? void : (e: string | React.ChangeEvent<HTMLInputElement>) => void;
  };
  setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void;
}

export const DatePicker: FC<Props> = (props) => {
  const DAYS = ['Ma', 'Ti', 'Ke', 'To', 'Pe', 'La', 'Su'];
  const [dayCount, setDayCount] = useState<Array<number>>([]);
  const [blankDays, setBlankDays] = useState<Array<number>>([]);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [datepickerHeaderDate, setDatepickerHeaderDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [type, setType] = useState<DatepickerType>('date');
  const {inputId} = props

  const decrement = () => {
    switch (type) {
      case 'date':
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
      case 'month':
        setDatepickerHeaderDate((prev) => subYears(prev, 1));
        break;
      case 'year':
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
    }
  };

  const increment = () => {
    switch (type) {
      case 'date':
        setDatepickerHeaderDate((prev) => addMonths(prev, 1));
        break;
      case 'month':
        setDatepickerHeaderDate((prev) => addYears(prev, 1));
        break;
      case 'year':
        setDatepickerHeaderDate((prev) => subMonths(prev, 1));
        break;
    }
  };

  const isToday = (day: number) =>
    isEqual(
      new Date(datepickerHeaderDate.getFullYear(), datepickerHeaderDate.getMonth(), day),
      selectedDate
    );

  const isDisabled = (day: number) =>
    isBefore(new Date(datepickerHeaderDate.getFullYear(), datepickerHeaderDate.getMonth(), day), new Date());

  const setDateValue = (date: number) => () => {
    if (isDisabled(date)) {
      return
    }
    const value = new Date(
      datepickerHeaderDate.getFullYear(),
      datepickerHeaderDate.getMonth(),
      date
    )
    props.setFieldValue('deliveryDate', format(value, 'dd.MM.yyyy', {locale: fi}))
    setSelectedDate(
      value
    );
    setShowDatepicker(false);
  };

  const getDayCount = (date: Date) => {
    const daysInMonth = getDaysInMonth(date);

    // find where to start calendar day of week
    const dayOfWeek = getISODay(new Date(date.getFullYear(), date.getMonth(), 1));
    console.log('dayOfWeek', dayOfWeek)
    const blankdaysArray = [];
    for (let i = 1; i < dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    setBlankDays(blankdaysArray);
    setDayCount(daysArray);
  };

  const isSelectedMonth = (month: number) =>
    isEqual(
      new Date(selectedDate.getFullYear(), month, selectedDate.getDate()),
      selectedDate
    );

  const setMonthValue = (month: number) => () => {
    setDatepickerHeaderDate(
      new Date(
        datepickerHeaderDate.getFullYear(),
        month,
        datepickerHeaderDate.getDate()
      )
    );
    setType('date');
  };

  const toggleDatepicker = () => setShowDatepicker((prev) => !prev);

  const showMonthPicker = () => setType('month');

  const showYearPicker = () => setType('date');

  useEffect(() => {
    getDayCount(datepickerHeaderDate);
  }, [datepickerHeaderDate]);

  return (

    <div className="relative">
      <Field type="hidden" name="date" value={selectedDate.toISOString()}/>
      <Field
        type="text"
        name={inputId}
        id={inputId}
        readOnly
        className="cursor-pointer block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
        placeholder="Valitse päivämäärä"
        onClick={toggleDatepicker}
      />
      <div
        className="cursor-pointer absolute top-0 right-0 px-3 py-2"
        onClick={toggleDatepicker}
      >
        <svg
          className="h-6 w-6 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      </div>
      {showDatepicker && (
        <div
          className="bg-white mt-12 rounded-lg shadow p-4 absolute top-0 left-0"
          style={{width: '17rem'}}
        >
          <div className="flex justify-between items-center mb-2">
            <div>
              <button
                type="button"
                className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                onClick={decrement}
              >
                <svg
                  className="h-6 w-6 text-gray-500 inline-flex"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
            </div>
            {type === 'date' && (
              <div
                onClick={showMonthPicker}
                className="flex-grow p-1 text-lg font-bold text-gray-800 cursor-pointer hover:bg-gray-200 rounded-lg"
              >
                <p className="text-center">
                  {format(datepickerHeaderDate, 'MMMM', {locale: fi})}
                </p>
              </div>
            )}
            <div
              onClick={showYearPicker}
              className="flex-grow p-1 text-lg font-bold text-gray-800 cursor-pointer hover:bg-gray-200 rounded-lg"
            >
              <p className="text-center">
                {format(datepickerHeaderDate, 'yyyy', {locale: fi})}
              </p>
            </div>
            <div>
              <button
                type="button"
                className="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                onClick={increment}
              >
                <svg
                  className="h-6 w-6 text-gray-500 inline-flex"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
          </div>
          {type === 'date' && (
            <>
              <div className="flex flex-wrap mb-3 -mx-1">
                {DAYS.map((day, i) => (
                  <div
                    key={i}
                    style={{width: '14.26%'}}
                    className="px-1"
                  >
                    <div className="text-gray-800 font-medium text-center text-xs">
                      {day}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-wrap -mx-1">
                {blankDays.map((_, i) => (
                  <div
                    key={i}
                    style={{width: '14.26%'}}
                    className="text-center border p-1 border-transparent text-sm"
                  ></div>
                ))}
                {dayCount.map((d, i) => (
                  <div
                    key={i}
                    style={{width: '14.26%'}}
                    className="px-1 mb-1"
                  >
                    <div
                      onClick={setDateValue(d)}
                      className={`cursor-pointer text-center text-sm leading-none rounded-full leading-loose transition ease-in-out duration-100 
                      ${
                        isToday(d)
                          ? 'bg-brand text-white'
                          : isDisabled(d) ? 'text-gray-300' : 'text-gray-700 hover:bg-brand'
                      }`
                      }
                    >
                      {d}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {type === 'month' && (
            <div className="flex flex-wrap -mx-1">
              {Array(12)
                .fill(null)
                .map((_, i) => (
                  <div
                    key={i}
                    onClick={setMonthValue(i)}
                    style={{width: '25%'}}
                  >
                    <div
                      className={`cursor-pointer p-5 font-semibold text-center text-sm rounded-lg hover:bg-gray-200 ${
                        isSelectedMonth(i)
                          ? 'bg-brand text-white'
                          : 'text-gray-700 hover:bg-blue-200'
                      }`}
                    >
                      {format(
                        new Date(
                          datepickerHeaderDate.getFullYear(),
                          i,
                          datepickerHeaderDate.getDate()
                        ),
                        'MMM',
                        {locale: fi}
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>

  );
}