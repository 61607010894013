import React from 'react'

export const Footer = () => {
  return (
    <footer className="bg-zinc-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">Footer</h2>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="flex flex-col space-y-4 items-center">
          <img className="h-24 text-center" src="https://cdn.mainiomuuttopalvelu.fi/images/Mainio_logo-1920w.png"
               alt="Mainio Muuttopalvelu" />
        </div>

        <div className="mt-6">
          <p className="text-base text-white xl:text-center">&copy; 2022 Mainio Muuttopalvelu Oy. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}