import React from 'react'
import {Button} from '../Button';
import {FormContext, FormContextType} from '../../context/formContext';
import {Formik, Form, Field} from 'formik';
import {OfferRequestContext, OfferRequestContextType} from '../../context/offerRequestContext';
import {useTranslation} from 'react-i18next';

export const ExtraInfo = () => {
  const {previousStep, nextStep} = React.useContext(FormContext) as FormContextType;
  const {
    sendOfferRequest,
    upsertOfferRequest,
    offerRequest
  } = React.useContext(OfferRequestContext) as OfferRequestContextType;
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        description: offerRequest?.description || '',
        onlyDriver: offerRequest?.onlyDriver !== undefined ? offerRequest.onlyDriver : false,
        pianoIncluded: offerRequest?.pianoIncluded !== undefined ? offerRequest.pianoIncluded : false,
      }}
      onSubmit={async (values) => {
        // same shape as initial values
        console.log(values);
        const {description, onlyDriver} = values
        const updatedRequest = upsertOfferRequest({description, onlyDriver})
        if (updatedRequest) {
          const response = await sendOfferRequest(updatedRequest)
          if (response?.id) {
            nextStep()
          }
        }
      }}
    >
      {() => (
        <Form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">{t('Other information')}</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                </p>
              </div>

              <div className="space-y-6 sm:space-y-5">

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t('Description of the move')}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      id="description"
                      as="textarea"
                      name="description"
                      rows={3}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                      defaultValue={''}
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      {t('Are you going to participate in the move yourself? Do you need moving boxes, moving equipment’s, help with packing and unpacking? Is there some extraordinary big furniture’s?  Is there a warehouse/storage in addition to the apartment? Is it possible to drive the moving truck close to the front door?')}
                    </p>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="onlyDriver" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t('I only need a driver')}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      id="onlyDriver"
                      type="checkbox"
                      name="onlyDriver"
                      className="block h-4 w-4 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                    />
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="pianoIncluded" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t('Does the move include piano?')}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      id="pianoIncluded"
                      type="checkbox"
                      name="pianoIncluded"
                      className="block h-4 w-4 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="pt-5 pb-12">
            <div className="flex justify-center">
              <Button onClick={() => previousStep()} type="button">{t('Previous')}</Button>
              <Button type="submit">{t('Lähetä')}</Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}