import React, { useState } from 'react'
import {Button} from '../Button';
import {FormContext, FormContextType} from '../../context/formContext';
import {Form, Formik, Field} from 'formik';
import {OfferRequestContext, OfferRequestContextType} from '../../context/offerRequestContext';
import {boolean, object, string} from 'yup';
import {ExclamationCircleIcon} from '@heroicons/react/outline';
import {useTranslation} from 'react-i18next';

const FromSchema = object().shape({
  houseType: string().required(),
  street: string().min(2).max(50).required(),
  postalCode: string().min(5).max(5).required(),
  city: string().required(),
  size: string().required(),
  elevator: boolean().required(),
});


const renderFloorOptions = () => {
  const floors = [];
  for (let i = 1; i < 12; i++) {
    floors.push(<option key={i}>{i}</option>);
  }
  return floors;
}

export const From = () => {
  const {previousStep, nextStep} = React.useContext(FormContext) as FormContextType;
  const {upsertOfferRequest, offerRequest} = React.useContext(OfferRequestContext) as OfferRequestContextType;
  const [hasElevator, setHasElevator] = useState<boolean|null>(null)
  const [isElevatorPossible, setIsElevatorPossible] = useState(false)
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        houseType: offerRequest?.moveFrom.houseType || '',
        street: offerRequest?.moveFrom.street || '',
        postalCode: offerRequest?.moveFrom.postalCode || '',
        city: offerRequest?.moveFrom.city || '',
        size: offerRequest?.moveFrom.size || '',
        elevator: offerRequest?.moveFrom.elevator !== undefined ? offerRequest.moveFrom.elevator : false,
        floor: offerRequest?.moveFrom.floor || '1',
        elevatorSize: offerRequest?.moveFrom.elevatorSize || '1'
      }}
      validationSchema={FromSchema}
      onSubmit={values => {
        // same shape as initial values
        console.log(values);
        upsertOfferRequest({moveFrom: values})
        nextStep()
      }}
    >
      {({errors, touched, handleChange, setFieldValue}) => (
        <Form className="space-y-8 divide-y divide-gray-200">
          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">{t('From where?')}</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('Add details from where you are moving from')}</p>
              </div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="country" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t('Type of apartment')}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      as="select"
                      id="houseType"
                      name="houseType"
                      autoComplete="houseType"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:max-w-xs sm:text-sm"
                    >
                      <option></option>
                      <option>{t('Flat')}</option>
                      <option>{t('Terraced house')}</option>
                      <option>{t('Semi-detached house')}</option>
                      <option>{t('House')}</option>
                      <option>{t('Loft house')}</option>
                      <option>{t('Storage')}</option>
                      <option>{t('Company')}</option>
                      <option>{t('Something else')}</option>
                    </Field>
                    {errors.houseType && touched.houseType &&
                      <>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                        </div>
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {t('Type of apartment is mandatory')}
                        </p>
                      </>
                    }
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="street" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('Street')}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="text"
                      name="street"
                      id="street"
                      autoComplete="street-address"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                    />
                    {errors.street && touched.street &&
                      <>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                        </div>
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {t('Street has to be between 2 and 50 characters long')}
                        </p>
                      </>
                    }
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('Zip code')}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      autoComplete="postal-code"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:max-w-xs sm:text-sm"
                    />
                    {errors.postalCode && touched.postalCode &&
                      <>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                        </div>
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {t('Zip code has to be 5 characters long')}
                        </p>
                      </>
                    }
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('City')}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="address-level2"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:max-w-xs sm:text-sm"
                    />
                    {errors.city && touched.city &&
                      <>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                        </div>
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {t('City is mandatory')}
                        </p>
                      </>
                    }
                  </div>
                </div>


                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="size" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('Area of apartment (m2)')}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      type="text"
                      name="size"
                      id="size"
                      autoComplete="off"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:max-w-xs sm:text-sm"
                    />
                    {errors.size && touched.size &&
                      <>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                        </div>
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {t('Area of apartment is mandatory')}
                        </p>
                      </>
                    }
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="floor" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    {t('Floor')}
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Field
                      as="select"
                      id="floor"
                      name="floor"
                      autoComplete="floor"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:max-w-xs sm:text-sm"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(event)
                        const isPossible = parseInt(event.target.value) > 1
                        setIsElevatorPossible(isPossible)
                        if (!isPossible) {
                          setHasElevator(false)
                          setFieldValue('elevator', undefined)
                        }
                      }}
                    >
                      {renderFloorOptions()}
                    </Field>
                    {errors.floor && touched.floor &&
                      <>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                        </div>
                        <p className="mt-2 text-sm text-red-600" id="email-error">
                          {t('Floor is mandatory')}
                        </p>
                      </>
                    }
                    <p className="mt-2 text-sm text-gray-500">{t('Floor 1 is on ground level')}</p>
                  </div>
                </div>

                {isElevatorPossible &&
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="floor" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('Is there elevator?')}
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <fieldset className="mt-4">
                        <legend className="sr-only">{t('Is there elevator?')}</legend>
                        <div className="space-y-4">
                          <div className="flex items-center">
                            <Field id="elevator-true" name="elevator" type="radio"
                                   className="h-4 w-4 border-gray-300 text-brand focus:ring-brand" value="true"
                                   onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                     handleChange(event)
                                     setHasElevator(event.target.value === 'true')
                                   }}
                            />
                            <label htmlFor="elevator"
                                   className="ml-3 block text-sm font-medium text-gray-700">{t('Yes')}</label>
                          </div>

                          <div className="flex items-center">
                            <Field id="elevator-false" name="elevator" type="radio"
                                   className="h-4 w-4 border-gray-300 text-brand focus:ring-brand" value="false"
                                   onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                     handleChange(event)
                                     setHasElevator(event.target.value === 'true')
                                   }}
                            />
                            <label htmlFor="elevator" className="ml-3 block text-sm font-medium text-gray-700">{t('No')}</label>
                          </div>

                        </div>
                      </fieldset>
                    </div>
                  </div>
                }

                {hasElevator &&
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="elevatorSize" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      {t('Elevator person amount')}
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <Field
                        id="elevatorSize"
                        name="elevatorSize"
                        type="text"
                        autoComplete="off"
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand sm:text-sm"
                      />
                    </div>
                  </div>
                }

              </div>
            </div>

          </div>

          <div className="pt-5 pb-12">
            <div className="flex justify-center">
              <Button onClick={() => previousStep()} type="button">{t('Previous')}</Button>
              <Button type="submit">{t('Next')}</Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}