import {OfferRequest} from '@api/models/offerRequest';

const API_URL_BASE = 'https://api.mainiomuuttopalvelu.fi/offers'

const postData = async (endpoint = '', data = {}): Promise<OfferRequest|undefined> => {
  // Default options are marked with *
  const response = await fetch(`${API_URL_BASE}/${endpoint}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    body: JSON.stringify(data)
  });
  return response.json();
}

export const createOfferRequest = async (p: OfferRequest): Promise<OfferRequest|undefined> => {
  try {
    return await postData('request', p)
  } catch (e) {
    console.log(e)
  }
}